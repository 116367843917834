

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Placeholder extends Vue {
  @Prop({ type: String, required: true }) img!: string;
  @Prop({ type: String, required: true }) subTitle!: string;
  @Prop({ type: String, default: '' }) description!: string;
  @Prop({ type: String, default: '160px' }) paddingTop!: string;
}
